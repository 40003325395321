












































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Watch } from 'vue-property-decorator'

// components
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import ChartExercise from '@/components/charts/ChartExercise.vue'
import ChartLesson from '@/components/charts/ChartLesson.vue'
import Hearts from '@/components/Hearts.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import StatusIcon from '@/components/StatusIcon.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
import UserCard from '@/components/cards/UserCard.vue'
import ButtonTextIcon from '@/components/_uikit/buttons/ButtonTextIcon.vue'
import MasterGroupsSearch from '@/components/_uikit/search/MasterGroupsSearch.vue'
import MasterEducationCard from '@/components/cards/MasterEducationCard.vue'
import LinkTabs from '@/components/_uikit/LinkTabs.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import ManagerGroupsModule from '@/store/modules/manager/groups'
import DictionaryModule from '@/store/modules/dictionary'
// types
import {
  ManagerMasterGroupsMasterGroupIdMastersGetParams, MasterShortResource, Permission } from '@/store/types'
// utils
import { formatDate, getCurrentMonth, openNewTab } from '@/utils/functions'

@Component({
  components: {
    Autocomplete,
    ButtonTextIcon,
    ChartExercise,
    ChartLesson,
    Hearts,
    LinkTabs,
    MasterEducationCard,
    MasterGroupsSearch,
    Select,
    StatusIcon,
    TextInput,
    UserCard,
  },
})
export default class MentorMastersTable extends Mixins(NotifyMixin, PermissionsMixin) {
  private mastersInGroup: MasterShortResource[] = []
  private isLoaded = false

  private get filter() {
    return ManagerGroupsModule.groupMastersFilter
  }

  private set filter(filter: ManagerMasterGroupsMasterGroupIdMastersGetParams) {
    ManagerGroupsModule.setGroupMastersFilter(filter)
    this.$emit('onFilterChange', this.filter)
    this.fetchMasterInGroup()
  }

  private handleFilter(field: keyof ManagerMasterGroupsMasterGroupIdMastersGetParams, value: string) {
    this.filter = {
      ...this.filter,
      [field]: value,
    }
  }

  private mounted () {
    DictionaryModule.searchMasterGroups({ self: true })
      .then(response => {
        if (response.length && !this.currentMyMasterGroupId) {
          this.currentMyMasterGroupId = response[0].id
          this.filter.monthId = this.currentMyMasterGroupMonth?.value as number
          this.$emit('onMasterGroupChange', this.currentMyMasterGroupId)
        }
      })
      .catch(this.notifyError)
      .finally(() => this.isLoaded = true)

    if (this.currentMyMasterGroupId) {
      this.fetchMasterInGroup()
    }
    this.$bus.$on('exportMastersList', this.handleExport)
  }

  private destroyed() {
    this.$bus.$off('exportMastersList', this.handleExport as any)
  }

  private get currentMyMasterGroup() {
    if (DictionaryModule.masterGroupsSelect.length) {
      return this.currentMyMasterGroupId ? DictionaryModule.masterGroupsSelect.find(masterGroup => masterGroup.id === this.currentMyMasterGroupId) : DictionaryModule.masterGroupsSelect[0]
    }
    return null
  }

  private get currentMyMasterGroupId() {
    return ManagerGroupsModule.currentMyMasterGroupId
  }

  private set currentMyMasterGroupId(value: number | null) {
    ManagerGroupsModule.setCurrentMyMasterGroup(value)
  }

  private get currentMyMasterGroupMonth() {
    if (this.currentMyMasterGroup) {
      return getCurrentMonth(this.currentMyMasterGroup.months)
    }
    return null
  }

  private get months() {
    return this.currentMyMasterGroup ? this.currentMyMasterGroup.months.map(item => ({
      name: formatDate(item.name, 'LLLL yyyy'),
      value: item.value,
    })) : []
  }

  private forceToUnmountComponent() {
    this.notifyError('У вас нет ни одной мастер группы для работы в данном разделе')
      .then(() => {
        this.$router.push({ name: 'profile' })
      })
  }

  private handleRowClick (masterData: MasterShortResource) {
    let route = 'manager.education.masters.item.lessons'

    if (!this.hasPermission(Permission.MASTER_LESSONS_VIEW)) {
      route = 'manager.education.masters.item.exercises'
    }

    if (!this.hasPermission(Permission.MASTER_EXERCISES_VIEW)) {
      route = 'manager.education.masters.item.information'
    }

    if (this.filter.monthId) {
      this.$router.push({
        name: route,
        params: {
          masterUUID: masterData.user.uuid,
        },
        query: {
          groupID: (this.currentMyMasterGroupId as number).toString(),
          monthID: this.filter.monthId.toString(),
        },
      })
    } else {
      this.notifyError('Выберите месяц')
    }

  }

  private handleExport () {
    openNewTab(`/manager/master-groups/${this.currentMyMasterGroupId}/masters/export`, this.filter)
  }

  @Bind
  @Debounce(200)
  private fetchMasterInGroup () {
    if (this.currentMyMasterGroupId) {
      ManagerGroupsModule.fetchGroupMasters({
        masterGroupID: this.currentMyMasterGroupId as number,
      })
        .then(response => this.mastersInGroup = response)
        .catch(this.notifyError)
    }
  }

  @Watch('currentMyMasterGroupId')
  watchMasterGroup () {
    this.filter.monthId = this.currentMyMasterGroupMonth?.value as number
    this.$emit('onFilterChange', this.filter)
    this.$emit('onMasterGroupChange', this.currentMyMasterGroupId)
    this.fetchMasterInGroup()
  }
}
