

































import { Component, Prop, Vue } from 'vue-property-decorator'

import UserCard from '@/components/cards/UserCard.vue'
import ChartExercise from '@/components/charts/ChartExercise.vue'
import ChartLesson from '@/components/charts/ChartLesson.vue'
import Hearts from '@/components/Hearts.vue'
import { MasterShortResource } from '@/store/types'

@Component({
  components: {
    ChartExercise,
    ChartLesson,
    Hearts,
    UserCard,
  },
})
export default class MasterEducationCard extends Vue {
  @Prop({ required: true })
  private master!: MasterShortResource
}
